* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Arial;
}

.row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.column {
  flex: 12.5%;
  max-width: 12.5%;
  padding: 0 4px;
}

.column img {
  border-radius: 3px;
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
  height: auto;
  background: #ecf0f1;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .column {
    flex: 33.33%;
    max-width: 33.33%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  /*.column {
    flex: 100%;
    max-width: 100%;
  }*/
}
