body {
  background: rgb(252, 252, 252);
}

.App {
  text-align: center;
}

.header {
  text-align: left;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 16px;
}

.trip-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.badge {
  background-color: #f2f2f2;
  border-radius: 8px;
  padding: 8px;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  text-decoration: none;
  color: #17181c;
}

.badge:hover {
  background-color: #d8d8d8;
}

.fab {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 56px;
  height: 56px;
  background-color: #f2f2f2;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.3s;
  padding-top: 5px;
}

.fab:hover {
  background-color: #d8d8d8;
}

.fab svg {
  width: 24px;
  height: 24px;
  fill: black;
}

.main-title {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 60px;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 1;
  color: #282c34;
  text-transform: uppercase;
}

@media screen and (max-width: 800px) {
  .main-title {
    font-size: 40px;
  }
}

.main-description {
  font-size: larger;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  line-height: 32px;
  color: #282c34;
  margin-top: 8px;
  margin-bottom: 8px;
}

.spinner-container {
  height: 30rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: normal;
}

.spinner-items:nth-child(1) {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

.loading-in-progress {
  display: inline-block;
  padding: 5px 10px;
  background-color: #cccccc;
  border-radius: 10px;
  margin-top: 8px;
}

.destination-title-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 8px;
  margin-top: 8px;
}

.destination-title-text {
  font-size: 50px;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 1;
  color: #282c34;
  text-align: left;
}

@media screen and (max-width: 800px) {
  .destination-title-text {
    font-size: 35px;
  }
}

.destination-subtitle-text {
  font-size: larger;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  line-height: 32px;
  color: #282c34;
  margin-left: 2px;
  text-align: left;
}

.delete-media-div {
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  padding: 3px;
  opacity: 1;
  background-color: rgba(216, 217, 221, 0.6);
  border-radius: 30px;
  position: relative;
  transform: translateX(5px) translateY(-25vh);
}

.button {
  color: #040c63;
  background-color: white;
  border-width: 1;
  border-style: solid;
  border-color: #040c63;
  padding: 8px;
  margin-left: 32px;
  margin-top: 8px;
  border-radius: 4px;
  display: flex;
  font-weight: bold;
}

.button:hover {
  color: white;
  background-color: #040c63;
  border-width: 1;
  border-color: transparent;
}

.small-button {
  color: #040c63;
  background-color: white;
  border-width: 1;
  border-style: solid;
  border-color: #040c63;
  margin-left: 4px;
  border-radius: 4px;
  display: flex;
}

.small-button:hover {
  color: white;
  background-color: #040c63;
  border-width: 1;
  border-color: transparent;
}

.row-input {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 4px;
}

.col-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 4px;
  margin-left: 8px;
}

.title-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.upload-container {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8;
  border-radius: 8px;
  height: 32px;
  width: 32px;
  border-width: 1;
  border-style: dashed;
  border-color: #e3e4e8;
  background-color: #f1f2f4;
  margin-left: 8px;
}

.upload-container:hover {
  border-color: #040c63;
  background-color: #e6e7ef;
}

.media-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.trip-column {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 16px;
}
