.App {
  text-align: center;
}

.gallery-view {
  max-width: 100vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  overflow-y: scroll;
  margin-left: 1vw;
  padding: 8px 0px 0px 8px;
  row-gap: 12px;
}

.thumbnail {
  max-height: 35vh;
  max-width: 90%;
  opacity: 1;
  display: block;
  backface-visibility: hidden;
}

.delete-media-div {
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  padding: 3px;
  opacity: 1;
  background-color: rgba(216, 217, 221, 0.6);
  border-radius: 30px;
  position: relative;
  transform: translateX(5px) translateY(-25vh);
}

.button {
  color: #040c63;
  background-color: white;
  border-width: 1;
  border-style: solid;
  border-color: #040c63;
  padding: 8px;
  margin-left: 32px;
  margin-top: 8px;
  border-radius: 4px;
  display: flex;
  font-weight: bold;
}

.button:hover {
  color: white;
  background-color: #040c63;
  border-width: 1;
  border-color: transparent;
}

.small-button {
  color: #040c63;
  background-color: white;
  border-width: 1;
  border-style: solid;
  border-color: #040c63;
  margin-left: 4px;
  border-radius: 4px;
  display: flex;
}

.small-button:hover {
  color: white;
  background-color: #040c63;
  border-width: 1;
  border-color: transparent;
}

.row-input {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 4px;
}

.col-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 4px;
  margin-left: 8px;
}

.title-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.upload-container {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8;
  border-radius: 8px;
  height: 32px;
  width: 32px;
  border-width: 1;
  border-style: dashed;
  border-color: #e3e4e8;
  background-color: #f1f2f4;
  margin-left: 8px;
}

.upload-container:hover {
  border-color: #040c63;
  background-color: #e6e7ef;
}

.media-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.trip-column {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 16px;
}
